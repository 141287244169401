import http from "../api";
// Constantes
const dataInicial = {
  loading: false,
  activo: false,
  error: "",
};

const USER_EXITO = "USER_EXITO";
const FECHA_ERROR = "FECHA_ERROR";
const MATRICULA_ERROR = "MATRICULA_ERROR";
const LOADING = "LOADING";
const CERRAR_SESION = "CERRAR_SESION";
const LOGOUT = "LOGOUT";

export default function usuarioReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case MATRICULA_ERROR:
      return { ...state, error: "matricula_error", loading: false };
    case FECHA_ERROR:
      return { ...state, error: "fecha_error", loading: false };
    case USER_EXITO:
      return {
        ...state,
        loading: false,
        activo: true,
      };
    case LOGOUT:
      return {
        ...state,
        loading: false,
        activo: false,
      };
    case CERRAR_SESION:
      return { ...dataInicial };
    default:
      return { ...state };
  }
}

// Acciones
export const Login = (infoData) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    const resp = await http.post("/loginQuiosco", infoData);
    let token = resp.data.access_token;
    sessionStorage.setItem("token", token);
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    dispatch({
      type: USER_EXITO,
    });
  } catch ({ response }) {
    if (response) {
      dispatch({
        type: response.data.message,
      });
    }
  }
};

export const logout = () => async (dispatch, _) => {
  dispatch({ type: LOGOUT });
};

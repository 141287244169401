import React from "react";
import "./Login.css";
import upnlogo from "../../assets/upnlogoazul.png";

import { useForm } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faSortNumericDown,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "bulma-toast";

import { useDispatch, useSelector } from "react-redux";

import { Login } from "../../redux/userDucks";

import { useHistory } from "react-router-dom";

const LoginComponent = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const error = useSelector((store) => store.usuario.error);
  const loading = useSelector((store) => store.usuario.loading);
  const activo = useSelector((store) => store.usuario.activo);

  React.useEffect(() => {
    if (loading) {
      switch (error) {
        case "matricula_error":
          toast({
            type: "is-danger",
            duration: 5000,
            message: "Matricula no encontrada",
          });
          break;
        case "fecha_error":
          toast({
            type: "is-danger",
            duration: 5000,
            message: "Ingresa correctamente la fecha",
          });
          break;
        default:
          break;
      }
    }

    if (activo) {
      history.replace("/profile");
    }
  }, [error, activo, loading, history]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (infoData) => {
    try {
      infoData.fechaNacimiento = infoData.fechaNacimiento.replace("-", "/");
      infoData.fechaNacimiento = infoData.fechaNacimiento.replace("-", "/");
      // dispatch(Login("infoData"));
      dispatch(Login(infoData));
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        toast({
          type: "is-danger",
          duration: 5000,
          message: error.response.data.message,
        });
      }
    }
  };

  const errorClass = (state) => {
    if (!state) {
      return "";
    } else {
      return "is-danger";
    }
  };

  return (
    <div className="columns is-narrow is-centered">
      <div className="column login-card">
        <form className="box is-centered" onSubmit={handleSubmit(onSubmit)}>
          <img src={upnlogo} alt="logo" />

          <p className="subtitle is-3">Inicio de Sesión</p>

          <div className="field">
            <label className="label">Matricula</label>
            <div className="control has-icons-left has-icons-right">
              <input
                {...register("matricula", {
                  required: "Es necesario la matricula",
                  minLength: {
                    value: 8,
                    message: "Minimo de caracteres 8",
                  },
                })}
                className={`input ${errorClass(errors.matricula)}`}
                type="tel"
                placeholder="Matricula"
              />

              {errors.matricula && (
                <p className="help is-danger">{errors.matricula.message}</p>
              )}

              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faSortNumericDown} />
              </span>
            </div>
          </div>

          <div className="field">
            <label className="label">Fecha de nacimiento</label>
            <div className="control has-icons-left has-icons-right">
              <input
                {...register("fechaNacimiento", {
                  required: "Es tu fecha de nacimiento",
                })}
                type="date"
                className={`input
                     ${errorClass(errors.fechaNacimiento)}`}
              />

              {errors.fechaNacimiento && (
                <p className="help is-danger">
                  {errors.fechaNacimiento.message}
                </p>
              )}
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faCalendar} />
              </span>
            </div>
          </div>

          <button className="button is-link is-6" disabled={loading}>
            Entrar
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginComponent;

import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/userDucks";

import "./FinishView.css";

const FinishView = () => {
  const { replace } = useHistory();
  const dispatch = useDispatch();

  sessionStorage.removeItem("token");
  dispatch(logout());

  setTimeout(() => {
    replace("/");
  }, 5000);

  return (
    <div className="finish_view">
      <div className="message">GRACIAS POR SU PREFERENCIA</div>
      <div className="warning">
        *EN CASO QUE NO SE HAYA IMPRESO SU DOCUMENTO, FAVOR DE PASAR CON
        SERVICIOS ESCOLARES*
      </div>
    </div>
  );
};

export default FinishView;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { toast } from "bulma-toast";

import PaymentData from "../../components/Payment/PaymentData";
import Header from "../../components/shared/Header/Header";
import axios from "../../api";
import { setInterval } from "../../utils/setInterval";
import "./Payment.css";

// NO BORRAR ESTA MADRE
/*global ccv_conectar 
  ccv_configMonedero 
  ccv_configBilletero 
  ccv_arr_monedas
  ccv_arr_billetes
  ccv_RetirarMonedas
  ccv_leerTubos
  coin_lecture
*/
// NO BORRAR ESTA MADRE

const Payment = () => {
  const [insertedMoney, setInsertedMoney] = useState(0);
  const [totalCoins, setTotalCoins] = useState(0);
  const [moneyToSubmit, setMoneyToSubmit] = useState(0);
  const [enablePayment, setEnablePayment] = useState(false);

  const [cambioExacto, setCambioExacto] = useState(false);
  const [doc, setDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTicketOptions, setShowTicketOptions] = useState(false);
  const [ticketModal, setTicketModal] = useState({
    open: false,
    ticket: null,
  });
  const { replace } = useHistory();

  const frame = document.getElementById("pdfDocument");
  const dataTramite = JSON.parse(localStorage.getItem("tramite"));
  let change = insertedMoney - dataTramite.cantidad;
  const remaining = dataTramite.cantidad - insertedMoney;
  let routeToMakeRequest;

  if (dataTramite.motivo.includes("orario")) {
    routeToMakeRequest = "/getHorarioPdfQuiosco";
  } else if (dataTramite.motivo.includes("calificaci")) {
    routeToMakeRequest = "/getConstanciaDeCalificacionesPdfQuiosco";
  } else if (dataTramite.motivo.includes("studio")) {
    routeToMakeRequest = "/constanciaEstudios/_";
  } else if (dataTramite.motivo.includes("ago")) {
    routeToMakeRequest = "/getPagoInscripcionResincripcion";
  }

  const disableMoneAndBille = () => {
    setTimeout(function () {
      ccv_configMonedero("00", "00", "00");
    }, 200);
    setTimeout(function () {
      ccv_configBilletero("00", "00");
    }, 500);
  };

  const verifyEnoughChange = (e) => {
    let restaCambio = 0;
    setCambioExacto(false);

    restaCambio = e - dataTramite.cantidad;
    const monedas = { c50: 0, p1: 0, p2: 0, p5: 0, p10: 0 };
    if (restaCambio >= 50) {
      toast({ message: "No damos cambio mayor a $50", type: "is-danger" });
      setCambioExacto(false);
    }
    if (restaCambio <= totalCoins) {
      if (restaCambio === 0) {
        setCambioExacto(true);
      }
      const total_monedas_en_tubos = [
        parseInt(coin_lecture[7], 16),
        parseInt(coin_lecture[6], 16),
        parseInt(coin_lecture[5], 16),
        parseInt(coin_lecture[4], 16),
      ];
      if (restaCambio < 50 && restaCambio > 0) {
        while (restaCambio !== 0) {
          if (restaCambio - 10 >= 0 && total_monedas_en_tubos[0] !== 0) {
            total_monedas_en_tubos[0]--;
            monedas.p10++;
            restaCambio -= 10;
          } else if (restaCambio - 5 >= 0 && total_monedas_en_tubos[1] !== 0) {
            total_monedas_en_tubos[1]--;
            monedas.p5++;
            restaCambio -= 5;
          } else if (restaCambio - 2 >= 0 && total_monedas_en_tubos[2] !== 0) {
            total_monedas_en_tubos[2]--;
            monedas.p2++;
            restaCambio -= 2;
          } else if (restaCambio - 1 >= 0 && total_monedas_en_tubos[3] !== 0) {
            total_monedas_en_tubos[3]--;
            monedas.p1++;
            restaCambio -= 1;
          } else if (restaCambio > 0) {
            toast({ message: "No tengo cambio exacto", type: "is-danger" });
            break;
          }
        }
        if (restaCambio === 0) {
          setCambioExacto(true);
        }
      }
    } else {
      toast({ message: "No hay cambio suficiente", type: "is-danger" });
    }
    console.log(monedas);
  };

  const enableMoneAndBille = () => {
    setTimeout(function () {
      ccv_configMonedero("01", "00", "00");
    }, 200);
    setTimeout(function () {
      ccv_configBilletero("01", "00");
    }, 500);
  };

  const onClickInsertMoney = async (e) => {
    e.preventDefault();
    ccv_conectar();
    enableMoneAndBille();
    setEnablePayment(true);
  };

  const onClickPayment = async (e) => {
    e.preventDefault();
    await fetchDocument();
    await makePayment();
    setLoading(true);
    returnChange();
    disableMoneAndBille();
  };

  const makePayment = async () => {
    try {
      await axios.post("paymentKiosco", { codigo: dataTramite.codigo });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDocument = async () => {
    try {
      const { data } = await axios.get(routeToMakeRequest, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
          "Cache-Control": "no-cache",
        },
      });
      setDoc(
        window.URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTicket = async () => {
    try {
      const { data } = await axios.get("/getKioskoTicket", {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
          "Cache-Control": "no-cache",
        },
      });
      setTicketModal({
        open: true,
        ticket: window.URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        ),
      });
      replace("/finish");
    } catch (error) {}
  };

  const onCloseTicketModal = () => {
    setTicketModal({
      open: false,
      ticket: null,
    });
    replace("/finish");
  };

  const sendTicketByEmail = async () => {
    try {
      await axios.get("/sendKioskoTicketByEmail");
      toast({
        message: "Se envío el ticket a tu correo electrónico",
        type: "is-success",
        position: "top-center",
      });
      setTimeout(() => replace("/finish"), 2000);
    } catch (error) {}
  };

  const returnChange = () => {
    const monedas = { c50: 0, p1: 0, p2: 0, p5: 0, p10: 0 };
    let dispensarMonedas = false;

    const total_monedas_en_tubos = [
      parseInt(coin_lecture[7], 16),
      parseInt(coin_lecture[6], 16),
      parseInt(coin_lecture[5], 16),
      parseInt(coin_lecture[4], 16),
    ];

    if (change > 0) {
      while (change !== 0) {
        if (change - 10 >= 0 && total_monedas_en_tubos[0] !== 0) {
          total_monedas_en_tubos[0]--;
          monedas.p10++;
          change -= 10;
        } else if (change - 5 >= 0 && total_monedas_en_tubos[1] !== 0) {
          total_monedas_en_tubos[1]--;
          monedas.p5++;
          change -= 5;
        } else if (change - 2 >= 0 && total_monedas_en_tubos[2] !== 0) {
          total_monedas_en_tubos[2]--;
          monedas.p2++;
          change -= 2;
        } else if (change - 1 >= 0 && total_monedas_en_tubos[3] !== 0) {
          total_monedas_en_tubos[3]--;
          monedas.p1++;
          change -= 1;
        }
      }
    }

    Object.entries(monedas).forEach((coin) => {
      if (!dispensarMonedas && coin[1] > 0) {
        dispensarMonedas = true;
      }
    });

    if (dispensarMonedas) {
      console.log(monedas);
      const dm50c = monedas.c50.toString(16);
      const dm1 = monedas.p1.toString(16);
      const dm2 = monedas.p2.toString(16);
      const dm5 = monedas.p5.toString(16);
      const dm10 = monedas.p10.toString(16);
      ccv_RetirarMonedas(dm50c, dm1, dm2, dm5, dm10);
    }
  };

  if (enablePayment) {
    setInterval(() => {
      const coins = Object.entries(ccv_arr_monedas).filter(
        (coin) => coin[1] > 0
      );
      const bills = Object.entries(ccv_arr_billetes).filter(
        (bill) => bill[1] > 0
      );
      let money = 0;

      coins.forEach((coin) => {
        const quantity = coin[0].replace("P", "");
        money += parseInt(quantity) * coin[1];
      });

      bills.forEach((bill) => {
        const quantity = bill[0].replace("P", "");
        money += parseInt(quantity) * bill[1];
      });

      setInsertedMoney(money);
    }, 500);
  }

  useEffect(() => {
    setInterval(
      () => {
        ccv_leerTubos();

        setTotalCoins(
          parseInt(coin_lecture[2], 16) * 0.5 +
            parseInt(coin_lecture[4], 16) * 1 +
            parseInt(coin_lecture[5], 16) * 2 +
            parseInt(coin_lecture[6], 16) * 5 +
            parseInt(coin_lecture[7], 16) * 10
        );
      },
      100,
      5
    );
  }, []);

  useEffect(() => {
    if (moneyToSubmit - dataTramite.cantidad > totalCoins) {
      toast({ message: "No hay suficiente cambio", type: "is-danger" });
    }
    if (loading) {
      setTimeout(async () => {
        if (!!frame?.src) {
          frame.contentWindow.print();
        }
      }, 500);
      setShowTicketOptions(true);
    }
    // eslint-disable-next-line
  }, [moneyToSubmit, loading]);

  const Monedero = () => {
    return (
      <div className="field">
        <label className="label">Dinero ingresado: ${insertedMoney}</label>
        {dataTramite.cantidad < insertedMoney ? (
          <label className="label">Tu cambio: ${change}</label>
        ) : (
          <label className="label">Dinero restante: ${remaining}</label>
        )}
      </div>
    );
  };

  const TicketOptions = () => {
    return (
      <div className="ticket-options">
        <p className="title">Ticket de compra</p>
        <button className={`button is-link`} onClick={sendTicketByEmail}>
          ENVIAR POR CORREO
        </button>
        <button className={`button is-link`} onClick={fetchTicket}>
          TOMAR FOTO
        </button>
      </div>
    );
  };

  return (
    <div className="App">
      <Header content={`${dataTramite.motivo} - $${dataTramite.cantidad}`} />
      <div className="payment">
        <div className="payment-card">
          {showTicketOptions ? (
            <>
              <TicketOptions />

              <div className={`modal ${ticketModal.open && "is-active"}`}>
                <div className="modal-background"></div>
                <div className="modal-content">
                  <div className="container-iframe">
                    <iframe
                      title="1"
                      src={`${ticketModal.ticket}#toolbar=0`}
                      className="responsive-iframe"
                    ></iframe>
                  </div>
                </div>
                <button
                  className="modal-close is-large"
                  aria-label="close"
                  onClick={onCloseTicketModal}
                ></button>
              </div>
            </>
          ) : (
            <>
              <PaymentData
                reason={dataTramite}
                setMoneyToSubmit={(e) =>
                  setMoneyToSubmit(parseFloat(e.target.value))
                }
              />
              {enablePayment && <Monedero />}
              {enablePayment ? (
                <button
                  className={`button is-primary ${loading && "is-loading"}`}
                  onClick={onClickPayment}
                  disabled={dataTramite.cantidad > insertedMoney}
                >
                  Pagar
                </button>
              ) : (
                <>
                  <div className="quantity-input">
                    <label className="label">Cantidad a ingresar</label>

                    <div className="control has-icons-left has-icons-right">
                      <input
                        type="tel"
                        className="input is-link is-small"
                        onChange={(e) => verifyEnoughChange(e.target.value)}
                        placeholder="Cantidad a ingresar"
                      />
                      <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faDollarSign} />
                      </span>
                    </div>
                  </div>
                  <button
                    className="button is-primary"
                    disabled={!cambioExacto}
                    onClick={onClickInsertMoney}
                  >
                    Insertar dinero
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div>
        <iframe
          src={doc}
          id="pdfDocument"
          style={{ display: "none" }}
          title="DOC"
        />
      </div>
    </div>
  );
};

export default Payment;

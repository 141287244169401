// Constantes
const dataInicial = {
  loading: false,
  error: "",
  info: [],
};

// const LOADING = "LOADING";
const SUCCES = "SUCCES";
// const ERROR_TRAMITE = "ERROR_TRAMITE";

export default function tramiteReducer(state = dataInicial, action) {
  switch (action.type) {
    case SUCCES:
      return { ...state, info: action.payload, loading: false };
    default:
      return { ...state };
  }
}

// Acciones
export const updateData = (tramite) => async (dispatch, getState) => {
  dispatch({
    type: SUCCES,
    payload: tramite,
  });
};

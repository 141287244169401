import axios from "axios";

export default axios.create({
  baseURL: "https://deploy-test.admonupn321zac.com/",
  headers: {
    common: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  },
});

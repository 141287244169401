import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bulma/css/bulma.min.css";

import Login from "./views/Login/Login";
import Profile from "./views/Profile/Profile";
import Payment from "./views/Payment/Payment";
import FinishView from "./views/FinishView/FinishView";

import { Provider } from "react-redux";
import { generateStore } from "./redux/store";

const store = generateStore();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/profile" component={Profile} />
        <Route path="/payment/:tramite" component={Payment} />
        <Route path="/finish" component={FinishView} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);

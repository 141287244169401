import React from "react";
import "./Header.css";
import upnlogo from "../../../assets/upnlogo.png";

const Header = ({ content }) => {
  return (
    <section className="hero banner is-bold">
      <div className="hero-body">
        <div className="container">
          <img src={upnlogo} alt="logo" />
          <h1 className="title">{content}</h1>
        </div>
      </div>
    </section>
  );
};

export default Header;

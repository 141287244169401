import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Header from "../../components/shared/Header/Header";
import { ReactComponent as ConstanciaIcon } from "../../assets/constancia.svg";
import { ReactComponent as CalificacionesIcon } from "../../assets/calificaciones.svg";
import { ReactComponent as HorarioIcon } from "../../assets/horario.svg";
import { ReactComponent as PagoIcon } from "../../assets/pago.svg";

import "./Profile.css";

//import { toast } from "bulma-toast";

import { updateData } from "../../redux/tramiteDucks";

import axios from "../../api";

const Profile = () => {
  const dispatch = useDispatch();
  const [dataTramite, setTramites] = useState([]);
  const history = useHistory();

  const fetchPayments = useCallback(async () => {
    try {
      const { data } = await axios.get(`/getKioscoReasonsPayment`);
      dispatch(updateData(data));
      setTramites(data);
    } catch ({ response }) {
      console.log(response);
    }
  }, [dispatch]);

  const onSelectedReason = (data) => {
    localStorage.setItem("tramite", JSON.stringify(data));
    history.push(`/payment/${data.codigo}`);
  };

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  const GridMotivos = ({ dataTramite }) => {
    const listItems = dataTramite.map((data, i) => {
      let icon;
      switch (data.motivo) {
        case "Pago de inscripción":
          icon = <PagoIcon />;
          break;
        case "Constancia de estudio":
          icon = <ConstanciaIcon />;
          break;
        case "Constancia de calificaciones":
          icon = <CalificacionesIcon />;
          break;
        default:
          icon = <HorarioIcon />;
          break;
      }

      return (
        <div className="column is-half has-text-centered" key={i}>
          <article
            className="tile is-child notification is-info card"
            onClick={() => onSelectedReason(data)}
          >
            {icon}
            <p className="title">{data.motivo}</p>
          </article>
        </div>
      );
    });

    return <div className="columns is-multiline">{listItems}</div>;
  };

  return (
    <div className="App">
      <Header content="Bienvenido" />
      <GridMotivos dataTramite={dataTramite} />
    </div>
  );
};

export default Profile;
